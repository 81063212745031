/* = Web Fonts
-------------------------------------------------------------- */

@font-face {
	font-family: 'JejuMyeongjo';
	src: url('./fonts/JejuMyeongjo/JejuMyeongjo.ttf');
	src: url('./fonts/JejuMyeongjo/JejuMyeongjo.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AppleSDGothicNeoM00';
	src: url('./fonts/AppleSDGothicNeoM00/AppleSDGothicNeoM.ttf');
	src: url('./fonts/AppleSDGothicNeoM00/AppleSDGothicNeoM.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AppleSDGothicNeoL00';
	src: url('./fonts/AppleSDGothicNeoL00/AppleSDGothicNeoL.ttf');
	src: url('./fonts/AppleSDGothicNeoL00/AppleSDGothicNeoL.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('./fonts/Lato/Lato-Light.ttf');
	src: url('./fonts/Lato/Lato-Light.ttf') format('truetype');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: 'LatoR';
	src: url('./fonts/Lato/Lato-Regular.ttf');
	src: url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
/* Preloader
-------------------------------------------------------------- */

@media (max-width: 600px) {
	.page-preloader {
		background-size: 80%;
	}
}

.page-preloader {
	position: fixed;
	top: 0;
	bottom: 0;
	padding: 20%;
	width: 100%;
	height: 100%;
	z-index: 100000;
	background: #fff url('./img/pageload-spinner2.gif') no-repeat;
	background-position: 55% 45%;
	animation-name: preloader-fade;
	animation-delay: 3.5s;
	animation-duration: 0.5s;
	animation-fill-mode: both;
	background-size: 12%;
}
@media (max-width: 1700px) {
	.page-preloader {
		background-size: 30%;
	}
}
@media (max-width: 1200px) {
	.page-preloader {
		background-size: 50%;
	}
}

.preloader-complete {
	animation-delay: 0.1s;
}
@keyframes preloader-fade {
	0% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

/* App
-------------------------------------------------------------- */

.App {
	text-align: center;
	/* width: 100vw; */

	-webkit-touch-callout: none !important;
	-webkit-user-select: none !important;
	-moz-user-select: -moz-none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}
.img {
	pointer-events: none !important;
}

.fadeImg {
	height: 100%;
}
